import React from 'react';
import '../styles/site.css';

export default function Home() {
  return (
    <div className="container">
      <div className="space-y-4 m-4">
        <div className="block">
          <img className="rounded-full object-scale-down h-48 border-2 border-black" src={'images/aaron_headshot.jpg'} alt="professional headshot of Aaron Silverman" />
        </div>
        <div className="block">
          <div className="inline-block bg-black bg-opacity-60 rounded-xl border-2 border-black text-white text-5xl p-4">
            Aaron Silverman
          </div>
        </div>
        <div className="block>">
          <div className="inline-block bg-black bg-opacity-60 rounded-xl border-2 border-black text-white text-xl p-4">
            Senior Software Engineer at <a className="underline" href="https://geteppo.com">Eppo</a>
          </div>
        </div>
        <div className="block>">
          <div className="inline-block bg-black bg-opacity-60 rounded-xl border-2 border-black text-white text-xl p-4">
            Previously:
            <ul className="list-disc list-outside">
              <li className="ml-6">VP and Head of Engineering at <a className="underline" href="https://teamengine.io">Team Engine</a></li>
              <li className="ml-6">SVP and Head of Engineering at <a className="underline" href="https://benefix.us">Benefix</a></li>
              <li className="ml-6">VP and Head of Engineering at <a className="underline" href="https://storyblocks.com">Storyblocks</a></li>
              <li className="ml-6">Co-creator of <a className="underline" href="https://doodleordie.com">Doodle or Die</a></li>
              <li className="ml-6">Senior Software Engineer at <a className="underline" href="https://opower.com">Opower</a></li>
              <li className="ml-6">Lead Software Engineer at <a className="underline" href="https://predictivetechnologies.com/">Applied Predictive Technologies</a></li>
            </ul>
          </div>
        </div>
        <div className="block>">
          <div className="inline-block bg-black bg-opacity-60 rounded-xl border-2 border-black text-white text-xl p-4">
            Connect with me:
            <ul>
              <li>
                <img className="h-5 inline-block mr-2" src="/images/linked_in_icon.png" />
                <a href="https://www.linkedin.com/in/silvermanaaron/">in/silvermanaaron</a>
              </li>
              <li>
                <img className="h-5 inline-block mr-2" src="/images/twitter_icon.png" />
                <a href="https://twitter.com/aarsilv">@aarsilv</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>);
}